var ENV = 'production';

var TITLE = "Jenarad.de - bikesharing for the City of Jena.";
var NAME = "Jenarad";
var SYSTEM_URL = "https://cykel.jenarad.de";
var API_ROOT = "https://cykel.jenarad.de/api";
var GBFS_URL = "https://cykel.jenarad.de/gbfs/gbfs.json";

var DEFAULT_LOCATION = [50.92784, 11.58801];
var DEFAULT_ZOOM = 15;

var I18N_MESSAGE_OVERRIDE = {
  "en": {
    "message": {
      "about": {
        "html": "An experimental <a href='https://docs.openbike.dev/index.html'>open source</a> bikesharing in Jena, currently in testing mode.<br /><b>More details</b> will be in near future available on <a href='https://blog.jenarad.de'>blog.jenarad.de</a>"
      }
    }
  },
  "de": {
    "message": {
      "app": {
        "unvalidated-message": "Sie können noch keine Fahrräder ausleihen, da ihr Account noch nicht freigeschalten wurde. Bitte <a href='mailto:contact@jenarad.de'>senden Sie uns</a> Ihren Nutzernamen, um sich freischalten zu lassen."
      },
      "about": {
        "html": "Ein experimentelles <a href='https://docs.openbike.dev/index.html'>open source</a> Bikesharing in Jena.<br /><b>Mehr Infos</b> gibt es bald unter <a href='https://blog.jenarad.de'>blog.jenarad.de</a>"
      }
    }
  }
};

var SUPPORT_TYPE = 'zammad';
var SUPPORT_URL = 'https://zammad.jenarad.de';
var SENTRY_DSN = '';

module.exports = { ENV, TITLE, NAME, API_ROOT, GBFS_URL, DEFAULT_LOCATION, DEFAULT_ZOOM, I18N_MESSAGE_OVERRIDE, SUPPORT_TYPE, SUPPORT_URL, SENTRY_DSN };
